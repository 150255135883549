import React from 'react';

import CompleteRegistration from '../../../components/CompleteRegistration/CompleteRegistration';
import FinishCompleteRegistration from '../../../components/CompleteRegistration/FinishCompleteRegistration';

function CompleteRegistrationHandler({ token }) {
  return (
    <div>{token ? <FinishCompleteRegistration token={token} /> : <CompleteRegistration />}</div>
  );
}

export default CompleteRegistrationHandler;
