import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ApiService } from '../../js/ApiService';
import JDFNLBrand from '../../styles/themes/status/assets/JD_FNL_Brand.svg';
import STATUSLogo from '../../styles/themes/status/assets/STATUS_logo_black.svg';
import useClient from '../util/useClient/useClient';

const schema = z.object({
  username: z.string().email({ message: 'Please enter a valid email address' })
});

function CompleteRegistration() {
  const {
    register,
    errors,
    handleSubmit,
    formState: { isSubmitted, isSubmitting, isValid }
  } = useForm({ resolver: zodResolver(schema), mode: 'onChange' });
  const [BEErrorMsg, setBBErrorMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const client = useClient();

  useEffect(() => {
    document.title = `Create Account | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
  }, [client]);

  const onSubmit = data => {
    const usernameLower = data.username.toLowerCase();
    ApiService.completeRegistrationEmail(usernameLower, client).then(res => {
      if (res.status === 200) {
        return setSuccess(true);
      }
      return setBBErrorMsg(
        `Something went wrong. Try again later or contact Customer Care at ${client === 'finl-web' ? '1-888-777-3949' : '1-855-423-1624'}.`
      );
    });
  };

  return (
    <div className="body bg-white" data-testid="complete-reg">
      <div className="d-flex flex-column align-items-center justify-content-center banner">
        <div className="mt-4">
          <img src={STATUSLogo} alt="STATUS logo" />
        </div>
        <div className="mt-3">
          <span className="font-weight-bold line-height-1">
            ONE ACCOUNT.
            <br />
            MORE ACCESS.
          </span>
        </div>
        <div className="mt-1">
          <img className="family-logo" src={JDFNLBrand} alt="JD FNL Brand logo" />
        </div>
        <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>
          VERIFY YOUR STATUS ACCOUNT
        </h1>
        <div className="text-center mb-3" style={{ lineHeight: '20px' }}>
          {!success && (
            <span>
              Enter the email you used to register in store and we&apos;ll send you a link to verify
              your account and set a password.
            </span>
          )}
          {success && (
            <span>Please check your email for the link we sent you to complete the process.</span>
          )}
        </div>
      </div>
      {!success && (
        <div className="form">
          <form
            id="loginForm"
            method="POST"
            action="/account/login"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-3">
              <input
                name="username"
                type="email"
                className="form-input"
                placeholder="Email"
                aria-describedby="Email"
                aria-labelledby="username"
                onChange={e => (e.target.value = e.target.value.toLowerCase())}
                ref={register()}
              />
              {errors.username && (
                <div className="form-text text-danger" role="alert">
                  {errors.username.message}
                </div>
              )}
            </div>
            <div className="text-center py-4">
              <input
                disabled={isSubmitted || isSubmitting || !isValid}
                type="submit"
                className="btn btn-primary btn-block"
                value="Submit"
              />
            </div>
          </form>
          <div className="text-center">
            <Link to="/otp" className="link text-center" style={{ fontSize: '13px' }}>
              Didn’t receive an email?
            </Link>
          </div>
          {BEErrorMsg && BEErrorMsg !== '' && (
            <div className="text-danger text-center px-4 py-4">{BEErrorMsg}</div>
          )}
        </div>
      )}
    </div>
  );
}

export default CompleteRegistration;
