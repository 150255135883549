import { useEffect } from 'react';

import Cookies from 'js-cookie';

function Riskified() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = false;
    script.type = 'text/javascript';

    const scriptCode =
      '(function() { ' +
      'function riskifiedBeaconLoad() {' +
      `var store_domain = '${Cookies.get('FNL_RISKIFIED_SHOP')}'; ` +
      `var session_id = '${Cookies.get('FNL_RISKIFIED_ID')}'; ` +
      "var url = ('https:' == document.location.protocol ? 'https://' : 'http://') " +
      "+ 'beacon.riskified.com?shop=' + store_domain + '&sid=' + session_id; " +
      "var s = document.createElement('script'); " +
      "s.type = 'text/javascript'; " +
      's.async = true; ' +
      's.src = url; ' +
      "var x = document.getElementsByTagName('script')[0]; " +
      'x.parentNode.insertBefore(s, x); ' +
      '} ' +
      'if (window.attachEvent) { ' +
      "window.attachEvent('onload', riskifiedBeaconLoad); " +
      '} else { ' +
      "window.addEventListener('load', riskifiedBeaconLoad, false); " +
      '} ' +
      '})();';

    script.appendChild(document.createTextNode(scriptCode));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
}

export default Riskified;
