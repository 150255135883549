import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function ModalSkeleton() {
  return (
    <>
      <div className="riskifiedModalSkeleton">
        <Skeleton height={50} width={'100%'} />
      </div>
      <div className="riskifiedModalSkeleton">
        <Skeleton height={25} width={'100%'} />
        <Skeleton height={25} width={'100%'} />
      </div>
      <div className="riskifiedModalSkeleton">
        <Skeleton height={25} width={'50%'} />
      </div>
      <div className="riskifiedSkeleton">
        <Skeleton height={50} width={'100%'} />
      </div>
      <div style={{ margin: '0 2.25rem' }}>
        <Skeleton height={50} width={'100%'} />
      </div>
      <div className="riskifiedSkeleton" style={{ marginBottom: '100vh' }}>
        <Skeleton height={25} width={'75%'} />
      </div>
    </>
  );
}
