export const ApiService = {
  fetchWithTextResponse: ({ url, method, headers, body }) => {
    return fetch(url, {
      method: method,
      headers: headers,
      body: body
    });
  },
  addUser: user => {
    return ApiService.fetchWithTextResponse({
      url: '/account/users/',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    });
  },
  requestResetPassword: payload => {
    return ApiService.fetchWithTextResponse({
      url: '/account/password/reset',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
  },
  validateResetToken: payload => {
    return ApiService.fetchWithTextResponse({
      url: '/account/password/validate',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
  },
  finishResetPassword: payload => {
    return ApiService.fetchWithTextResponse({
      url: '/account/password/',
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
  },
  changePassword: (payload, mobileRedirect) => {
    var url = mobileRedirect
      ? `/account/password/change?mobileRedirect=${mobileRedirect}`
      : '/account/password/change';
    return ApiService.fetchWithTextResponse({
      url: url,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
  },
  completeRegistrationEmail: (email, client) => {
    return ApiService.fetchWithTextResponse({
      url: `/account/users/enrollmentReset?email=${email}&clientId=${client}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  },
  validateOTP: token => {
    return ApiService.fetchWithTextResponse({
      url: `/account/users/validate?token=${token}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  },
  completeRegistrationAcc: payload => {
    return ApiService.fetchWithTextResponse({
      url: '/account/users/complete',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
  },
  validateUser: () => {
    return ApiService.fetchWithTextResponse({
      url: '/account/valid/',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  },
  validateChallengeToken: (challengeAccessToken, otherParams) => {
    const { clientId, redirectUri, codeChallenge, state } = otherParams;
    return ApiService.fetchWithTextResponse({
      url: `/account/auth/validateChallenge?token=${challengeAccessToken}&response_type=code&client_id=${clientId}&scope=read&redirect_uri=${redirectUri}&state=${state}&code_challenge_method=s256&code_challenge=${codeChallenge}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }
};
