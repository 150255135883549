import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ApiService } from '../../js/ApiService';
import useClient from '../util/useClient/useClient';
import useEnvSite from '../util/useEnvSite/useEnvSite';
import ErrorSummary from '../util/ErrorSummary/ErrorSummary';

import { passWordValidation } from '../../js/utils';

function ChangePassword() {
  const {
    register,
    errors,
    handleSubmit,
    getValues,
    formState: { isSubmitted, isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const client = useClient();
  const envSite = useEnvSite();
  const [password, setPassword] = useState('');
  const [passFocused, setpassFocused] = useState(false);
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    document.title = `Update Password | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
  }, [client]);

  const onSubmit = data => {
    ApiService.changePassword(
      {
        newPassword: data.newPassword,
        oldPassword: data.oldPassword
      },
      provideMobileRedirectValue(params)
    ).then(res => {
      if (res.status === 200) {
        setIsSuccess(true);
      }
      res.text().then(msg => setMessage(msg));
    });
  };

  const handlePassChange = e => {
    setPassword(e.target.value);
  };

  const provideMobileRedirectValue = params => {
    if (params.get('mobileRedirect') != null) {
      return params.get('mobileRedirect');
    }
    return null;
  };

  const showErrorSummary = () => {
    setpassFocused(true);
  };

  const hideErrorSummary = () => {
    setpassFocused(false);
  };

  return (
    <div className={isSuccess ? 'body' : 'body bg-white'}>
      <div className="bg-white d-flex flex-column align-items-center justify-content-center">
        <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>
          CHANGE PASSWORD
        </h1>
      </div>
      <div className="bg-white pb-3">
        {!isSuccess && (
          <div className="form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3 text-left">
                <input
                  name="oldPassword"
                  type="password"
                  className="form-input"
                  maxLength="25"
                  placeholder="Old Password"
                  aria-describedby="Old Password"
                  aria-labelledby="oldPassword"
                  ref={register({
                    required: {
                      value: true,
                      message: 'This field is required'
                    },
                    maxLength: {
                      value: 25,
                      message: 'The password is maximum 25 characters'
                    }
                  })}
                />
                {errors.oldPassword && (
                  <div className="form-text text-danger" role="alert">
                    {errors.oldPassword.message}
                  </div>
                )}
              </div>

              <div className="mb-3 text-left">
                <input
                  name="newPassword"
                  type="password"
                  className="form-input"
                  maxLength="25"
                  placeholder="New Password"
                  aria-describedby="New Password"
                  aria-labelledby="newPassword"
                  ref={register({ validate: passWordValidation })}
                  onChange={handlePassChange}
                  onFocus={showErrorSummary}
                  onBlur={hideErrorSummary}
                />
                {errors.newPassword && (
                  <div className="form-text text-danger" role="alert">
                    {errors.newPassword.message}
                  </div>
                )}
              </div>

              <ErrorSummary value={password} show={passFocused} />

              <div className="mb-3 text-left">
                <input
                  name="confirmPassword"
                  type="password"
                  className="form-input"
                  maxLength="25"
                  placeholder="Confirm Password"
                  aria-describedby="Email input"
                  aria-labelledby="confirmPassword"
                  ref={register({
                    required: 'You must confirm your new password',
                    validate: {
                      equalsNewPassword: value =>
                        value === getValues('newPassword') || 'You must confirm your new password'
                    }
                  })}
                />
                {errors.confirmPassword && (
                  <div className="form-text text-danger" role="alert">
                    {errors.confirmPassword.message}
                  </div>
                )}
              </div>

              <div className="text-center my-4">
                <input
                  disabled={isSubmitted || isSubmitting || !isValid}
                  type="submit"
                  className="btn btn-primary btn-block"
                  value="Submit"
                />
              </div>
            </form>
            {message !== '' && <div className="text-danger text-center">{message}</div>}
          </div>
        )}
        {isSuccess && (
          <div className="text-center pt-3">
            <p>{message || 'Your password has been changed'}</p>
          </div>
        )}
      </div>
      {isSuccess && (
        <div>
          <hr className="fl-hr"></hr>
          <div className="formFooter text-center">
            <a
              href={
                envSite.redirectURI
                  ? envSite.redirectURI
                  : `https://${envSite.env}.${envSite.site}.com/store/myaccount/profile.jsp`
              }
              className="btn btn-link"
            >
              Go back to {client === 'finl-web' ? 'FinishLine' : 'JDSports'}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangePassword;
