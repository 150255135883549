import moment from 'moment';

const upperCasePattern = /(?=.*[A-Z])/;
const lowerCasePattern = /(?=.*[a-z])/;
const specialCharPattern = /(?=.*[!"#$%'&()*+,-./:;<=>?@^_`{|}~])/;
const digitPattern = /(?=.*[0-9])/;

export const passWordValidation = {
  required: value => (value && value.trim() !== '') || 'Password is required.',
  upperCaseCheck: value => value && upperCasePattern.test(value),
  lowerCaseCheck: value => value && lowerCasePattern.test(value),
  specialCharCheck: value => value && specialCharPattern.test(value),
  digitCheck: value => value && digitPattern.test(value),
  lengthCheck: value => value && value.length >= 10 && value.length <= 25
};

export const dobValidation = {
  valid: value => !value || moment(value).isValid() || 'Please enter a valid date.',
  adult: value =>
    !value ||
    moment(value).isSameOrBefore(moment().subtract(13, 'years')) ||
    'You must be 13+ years old to register.'
};
