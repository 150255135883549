import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ApiService } from '../../js/ApiService';
import useClient from '../util/useClient/useClient';

import JDFNLBrand from '../../styles/themes/status/assets/JD_FNL_Brand.svg';
import STATUSLogo from '../../styles/themes/status/assets/STATUS_logo_black.svg';

function OTP() {
  const {
    register,
    errors,
    handleSubmit,
    formState: { isSubmitted, isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const client = useClient();

  useEffect(() => {
    document.title = `Create Account | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
  }, [client]);

  const expandOTPForm = () => {
    setShowOTPForm(true);
  };

  const onSubmit = data => {
    ApiService.validateOTP(data.passcode)
      .then(async res => {
        if (res.status === 200) {
          return res.json();
        }
        if (res.status === 400 || res.status === 404) {
          return res.json().then(d => setErrMsg(d.error));
        }
        return res.text().then(msg => setErrMsg(msg));
      })
      .then(resData => {
        if (!!resData && resData.token) {
          window.location.href = `https://${window.location.host}/completeRegistration?token=${data.passcode}`;
        }
      });
  };

  return (
    <div className="body bg-white">
      <div className="banner">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="mt-4">
            <img src={STATUSLogo} alt="STATUS logo" />
          </div>
          <div className="mt-3">
            <span className="font-weight-bold line-height-1">
              ONE ACCOUNT.
              <br />
              MORE ACCESS.
            </span>
          </div>
          <div className="mt-1">
            <img className="family-logo" src={JDFNLBrand} alt="JD FNL Brand logo" />
          </div>
          <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>
            COMPLETE STATUS ACCOUNT
            <br />
            REGISTRATION
          </h1>
        </div>
        <div className="text-left mb-4" style={{ lineHeight: '20px' }}>
          <strong>Didn&apos;t receive an email?</strong>
          <br />
          <span>Check your spam folder or</span>
          <br />
          <button onClick={expandOTPForm} className="link">
            click here to complete your registration.
          </button>
        </div>
        {showOTPForm && (
          <div>
            <ol style={{ paddingLeft: '16px' }}>
              <li>
                Call Customer Care to request a passcode:
                <br />
                {!!client && client === 'finl-web' && <strong>1-888-777-3949.</strong>}
                {!!client && client === 'jdsp-web' && <strong>1-855-423-1624.</strong>}
              </li>
              <li className="mt-4">Enter your one-time use passcode below:</li>
            </ol>
            <div className="form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 text-left">
                  <input
                    name="passcode"
                    type="text"
                    className="form-input"
                    placeholder="Passcode"
                    aria-describedby="Passcode"
                    aria-labelledby="passcode"
                    maxLength="6"
                    ref={register({
                      required: 'Passcode is required',
                      minLength: { value: 6, message: 'Passcode is 6 characters long' },
                      maxLength: { value: 6, message: 'Passcode is 6 characters long' }
                    })}
                  />
                  {errors.passcode && (
                    <div className="form-text text-danger" role="alert">
                      {errors.passcode.message}
                    </div>
                  )}
                </div>

                <div className="text-center my-4">
                  <input
                    disabled={isSubmitted || isSubmitting || !isValid}
                    type="submit"
                    className="btn btn-primary btn-block"
                    value="Submit"
                  />
                </div>
              </form>

              {errMsg !== '' && <div className="text-danger text-center pb-3">{errMsg}</div>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OTP;
