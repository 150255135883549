import React from 'react';
import './Loading.css';

function Loading({ inline }) {
  if (inline) {
    return (
      <div className="loadingIcon">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
  return (
    <div className="d-flex flex-row align-items-center justify-content-center vh-100">
      <div className="loadingIcon">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loading;
