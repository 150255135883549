const getRISKX = () => {
  return new Promise(r => {
    if (window.RISKX) {
      r(window.RISKX);
    } else {
      const i = setInterval(() => {
        if (window.RISKX) {
          clearInterval(i);
          r(window.RISKX);
        }
      }, 100);
    }
  });
};

export default getRISKX;
