import React from 'react';
import Loading from '../../components/util/Loading/Loading';
import useClient from '../../components/util/useClient/useClient';

const FNLTheme = React.lazy(() => import('../themes/fnl/fnlTheme'));
const JDTheme = React.lazy(() => import('../themes/jd/jdTheme'));
const STATUSTheme = React.lazy(() => import('../themes/status/statusTheme'));

function ThemeSelector({ children }) {
  const client = useClient();
  const ssStyles = JSON.parse(window.localStorage.getItem('ssStyles'));

  function LoadingTheme() {
    return (
      <div style={{ display: 'block', height: '100vh' }}>
        <div
          className="d-flex align-items-center justify-content-center mb-3"
          style={{ height: '80%' }}
        >
          <span>Loading theme...</span> <Loading inline="true" />
        </div>
      </div>
    );
  }

  return (
    <>
      {ssStyles && (
        <React.Suspense fallback={<LoadingTheme />}>
          {client.startsWith('finl') && <FNLTheme />}
          {client.startsWith('jdsp') && <JDTheme />}
          {children}
        </React.Suspense>
      )}
      {!ssStyles && (
        <React.Suspense fallback={<LoadingTheme />}>
          <STATUSTheme />
          {children}
        </React.Suspense>
      )}
    </>
  );
}

export default ThemeSelector;
