import Button from '@digitalshared/button';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { ApiService } from '../../js/ApiService';
import JDFNLBrand from '../../styles/themes/status/assets/JD_FNL_Brand.svg';
import STATUSLogo from '../../styles/themes/status/assets/STATUS_logo_black.svg';
import ModalSkeleton from '../ModalSkeleton';
import getRISKX from '../util/Riskified/getRISKX';
import useClient from '../util/useClient/useClient';
import useSiteKey from '../util/useSiteKey/useSiteKey';

const schema = z.object({
  username: z.string().email({ message: 'Please enter a valid email address' }),
  password: z.string().min(1, { message: 'Password is required.' }).max(25, {
    message: 'Password must be at most 25 characters.'
  })
});

export default function Login() {
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitted, isSubmitting, isValid }
  } = useForm({ resolver: zodResolver(schema), mode: 'onChange' });
  const search = useLocation().search;
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const [BEError, setBEError] = useState(urlParams.get('error') || false);
  const [BEErrorMsg, setBEErrorMsg] = useState(
    urlParams.get('displayError') || 'Something happened when logging in'
  );
  const [passInputType, setPassInputType] = useState('password');
  const { validateChallengeToken } = ApiService;
  const tokenNotFound = 'Challenge%20token%20not%20found';

  const siteKey = useSiteKey();
  const client = useClient();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const errorMessage = 'Something went wrong. Please try again.';

  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '50px, 25px',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden'
    }
  };

  useEffect(() => {
    document.title = `STATUS Sign In | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
    const params = {
      error: urlParams.get('error'),
      displayError: urlParams.get('displayError')
    };
    if (
      (params.error && params.displayError) ||
      window?.location?.pathname?.includes(tokenNotFound)
    ) {
      reset();
      setBEError(true);
      setBEErrorMsg(params.displayError);
      setLoading(false);
    }
    async function handleRISKX() {
      const RISKX = await getRISKX();
      RISKX.go('/login');
    }

    handleRISKX();
  }, [urlParams, client, reset]);

  const onSubmit = async (_, event) => {
    try {
      setLoading(true);
      event?.preventDefault();
      await event.target.submit();
    } catch (error) {
      console.error('Error submitting form', error);
      setLoading(false);
    }
  };

  const showPassword = () => {
    if (passInputType === 'password') {
      setPassInputType('text');
    } else {
      setPassInputType('password');
    }
  };

  function handleVerify(token) {
    setValue('recaptchaToken', token);
  }

  useLayoutEffect(() => {
    const widgetToken = urlParams.get('widgetToken');
    const challengeAccessToken = urlParams.get('challengeAccessToken');
    const clientId = urlParams.get('clientId');
    const redirectUri = urlParams.get('redirectUri');
    const codeChallenge = urlParams.get('codeChallenge');
    const state = urlParams.get('state');
    const otherParams = { clientId, redirectUri, codeChallenge, state };

    if (widgetToken && challengeAccessToken) {
      setLoading(true);
      setOpen(true);
      async function riskifiedMfaWidget() {
        if (window?.Riskified) {
          const callback = async (challengeAccessToken, otherParams) => {
            try {
              const response = await validateChallengeToken(challengeAccessToken, otherParams);
              const json = await response.json();
              const { status } = response;
              const { result } = json;
              if (window && status === 200 && result) {
                window.location.href = result;
              } else {
                setBEError(true);
                setBEErrorMsg(errorMessage);
              }
              setOpen(false);
            } catch (error) {
              console.error('Error validating challenge token', error);
              setBEError(true);
              setBEErrorMsg(errorMessage);
            }
          };
          const onVerifiedCallback = callback.bind(null, challengeAccessToken, otherParams);
          const widget = window.Riskified.renderMfaWidget;
          await widget(widgetToken, onVerifiedCallback);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
          console.error('Widget unavailable.');
        }
      }

      setTimeout(async () => {
        await riskifiedMfaWidget();
      }, 4000);
    }
  }, [urlParams, validateChallengeToken]);

  return (
    <>
      <div className="body">
        <div className="bg-white">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>
              SIGN IN
            </h1>
          </div>
          <div className="form">
            <form
              id="loginForm"
              method="POST"
              action="/account/login"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-3">
                <input
                  name="username"
                  type="email"
                  className="form-input"
                  placeholder="Email"
                  aria-describedby="Email"
                  aria-labelledby="username"
                  ref={register()}
                />
                {errors.username && (
                  <div className="form-text text-danger" role="alert">
                    {errors.username.message}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <input
                  name="password"
                  type={passInputType}
                  className="form-input"
                  maxLength="25"
                  placeholder="Password"
                  aria-describedby="Password"
                  aria-labelledby="password"
                  ref={register()}
                />
                <button type="button" onClick={showPassword} className="show-pass">
                  {passInputType === 'password' ? 'Show' : 'Hide'}
                </button>
                {errors.password && (
                  <div className="form-text text-danger" role="alert">
                    {errors.password.message}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <input name="recaptchaToken" type="hidden" ref={register()} />
                <span className="recaptchaMsg">
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a href="https://policies.google.com/privacy" className="recaptchaMsg">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href="https://policies.google.com/terms" className="recaptchaMsg">
                    Terms of Service
                  </a>{' '}
                  apply
                </span>
              </div>

              <Link to="/resetPassword" className="link text-left">
                Forgot your password?
              </Link>
              <div className="text-center py-4">
                <Button
                  category="primary"
                  disabled={isSubmitted || isSubmitting || !isValid || loading}
                  loading={loading}
                  type="submit"
                  className="btn btn-primary btn-block"
                >
                  {loading ? 'Signing in' : 'Sign In'}
                </Button>
              </div>
            </form>
            {BEError && <div className="text-danger text-center pb-4">{BEErrorMsg}</div>}
          </div>
        </div>
        <hr className="fl-hr"></hr>
        <div className="formFooter text-center">
          <img src={STATUSLogo} alt="STATUS Logo" />
          <span className="h1 mb-4 text-center" style={{ display: 'block', marginTop: '28px' }}>
            Gain Access. Get Cash. Boost Your STATUS.
          </span>
          <div className="text-center mb-3" style={{ lineHeight: '20px' }}>
            <strong>Sign up to receive an exclusive $10 Welcome Reward.</strong>
          </div>
          <div className="text-center mb-3" style={{ lineHeight: '20px' }}>
            <span>
              Create an account today and start earning earn 10 points for every $1 you spend.
            </span>
          </div>
          <div className="text-center mb-3" style={{ lineHeight: '20px' }}>
            <a
              href={
                client.startsWith('finl')
                  ? 'https://www.finishline.com/store/status'
                  : 'https://www.jdsports.com/status'
              }
              className="link"
            >
              Learn More
            </a>
          </div>
          <Link to="/register">
            <button className="btn btn-secondary btn-block" style={{ marginBottom: '32px' }}>
              CREATE ACCOUNT
            </button>
          </Link>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={siteKey} useEnterprise="true">
          <GoogleReCaptcha onVerify={handleVerify} />
        </GoogleReCaptchaProvider>
      </div>
      <Modal
        isOpen={open}
        onRequestClose={() => {
          reset();
          setOpen(false);
          setLoading(false);
          history.push('/login');
        }}
        style={modalStyle}
      >
        <div className="riskifiedModalContainer">
          <div className="riskifiedModalLogo">
            <img className="family-logo" src={JDFNLBrand} alt="JD FNL Brand logo" />
          </div>

          {loading && <ModalSkeleton />}
          <div style={{ width: '100%', height: '100%' }} id="mfa-widget"></div>
        </div>
      </Modal>
    </>
  );
}
Modal.setAppElement('body');
